body {
    color: #262728;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
    font-weight: 400;
    line-height: 1.2;
    font-family: "Domine", serif;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}
.bg-secondary {
    background-color: #fccb0a !important;
}
.bg-success {
    background-color: #5bbe6e !important;
}
.bg-info {
    background-color: #72a3de !important;
}
.bg-warning {
    background-color: #f5944d !important;
}
.bg-danger {
    background-color: #ec7780 !important;
}
.bg-dark {
    background-color: #212729 !important;
}
.bg-light {
    background-color: #d8dfe1 !important;
}
.bg-purple {
    background: #9b7ac1 !important;
}
.bg-pink {
    background-color: #dd81b2 !important;
}
.bg-teal {
    background-color: #72d5de !important;
}
.bg-muted {
    background-color: #f3f5f7 !important;
}
.table-success {
    background-color: #c8e6b3 !important;
}
.table-warning {
    background-color: #ffe7ab !important;
}
.table-danger {
    background-color: #ffa8a8 !important;
}
.table-info {
    background-color: #afdafd !important;
}
.table-light {
    background-color: #cdd6e0 !important;
}
.table-secondary {
    background-color: #ffe01b;
}
.table-pink {
    background-color: #fcb8ff !important;
}
.table-purple {
    background-color: #cfb8fe !important;
}
.table-green {
    background-color: #a5d0a0 !important;
}
.table-brown {
    background-color: #d8bfa6 !important;
}

#skip {
    position: fixed;
    left: -100%;
    top: -100%;
    transition: all 0.3s;
    z-index: 999;
}
#skip:focus {
    left: 50px;
    top: 50px;
}
