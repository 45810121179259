.nounderline {
    text-decoration: none;
}

.login-row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}

.revize-logo {
    margin-top: 10rem !important;
}

.login-form {
    text-align: center;
}

.login-bg {
    max-width: 100%;
    height: 100%;
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
    overflow: auto;
    position: fixed;
    object-fit: cover;
}
