.meeting-history-header > .offcanvas-title {
    width: 100% !important;
}

.view-suneditor > .sun-editor {
    border: none !important;
}

.view-suneditor > .sun-editor .se-toolbar {
    display: none !important;
    outline: none !important;
}

.view-suneditor .sun-editor .se-container .se-resizing-bar {
    display: none !important;
}

.view-suneditor .sun-editor .se-container .sun-editor-editable {
    padding: 0 !important;
}

.view-suneditor .sun-editor .se-wrapper .se-wrapper-inner {
    min-height: 0 !important;
}

.li-marker-bold::marker {
    font-weight: bold !important;
}

#edit-buttons{
    display: flex; 
    gap: 5px;
}