.label-container {
    width: 400px;
}

.user-selection-container {
    width: 500px;
    min-height: 150px;
}

.user-selection,
.user-selected {
    width: 40%;
}

.user-selection > ul,
.user-selected > ul {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    list-style-type: none;
    border-bottom: 1 px solid #eee;
}

.user-selection > ul > li,
.user-selected > ul > li {
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.user-selection > ul > li:hover,
.user-selected > ul > li:hover {
    background-color: #86b7fe;
    color: white;
}
