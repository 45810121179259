.drag-wrapper {
    line-height: 2.4;
    cursor: move;
}

.handle {
    display: inline;
    color: black;
    position: relative;
    right: 50%;
    left: 50%;
    width: 40px;
    padding: 10px;
    border-right: 1px solid #aaa;
    border-left: 1px solid #aaa;
    height: 100%;
}

.drag-wrapper > .btn {
    border-radius: 0 !important;
    color: white;
}

.topic-name {
    width: auto !important;
}

.topic-description-label {
    color: #737475;
}

.drag-here-parent{
    text-align: center;
    padding: 40px;
    border: 2px dotted #000;
}

.item{

}

.child-item{
    margin-left: 30px;
}